<template>
    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑卫健委':'新增卫健委'" :visible.sync="show" width="600px"
        class="selfInputBox selfInputBoxIV">
        <el-form v-if="formShow" ref="form" label-width="80px">

            <el-form-item label="卫健委名称" prop="">
                <el-input v-model="healthInfo.name" />
            </el-form-item>


            <!-- type: "",1总部门 2下属部门 -->
            <el-form-item label="卫健委类型" prop="">
                <el-radio v-model="healthInfo.type" :label="1" style="left: 20px;">总部门</el-radio>
                <el-radio v-model="healthInfo.type" :label="2">下属部门</el-radio>
            </el-form-item>


            <!-- has_child: "", //是否有子级：1有 2无 -->
            <!-- <el-form-item label="有无子级" prop="">
                <el-switch v-model="healthInfo.has_child" :active-value="1" active-color="#5BD995" active-text="有"
                    :inactive-value="2" inactive-color="#D9D9D9" inactive-text="无" class="switchSelfBox">
                </el-switch>
            </el-form-item> -->




            <el-form-item v-show="JSON.stringify(higher).length>2" label="上级单位" prop="">
                <el-input :value="higher.name" disabled />
            </el-form-item>




            <!-- <el-form-item label="角色权限" prop=""> -->
            <!-- <el-input disabled v-model="healthInfo.auth" /> -->
            <!-- <AlonePower ref="AlonePower" style="margin-left:20px;width:85%;height: 200px;" /> -->
            <!-- </el-form-item> -->



            <el-form-item label="排序" prop="">
                <el-input v-model="healthInfo.sort" />
            </el-form-item>



        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>






    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    import AlonePower from './AlonePower.vue'

    export default {

        components: {
            AlonePower,
        },

        // model: {
        //     prop: 'value',
        //     event: 'change'
        // },
        // props: {
        //     value: {
        //         type: Object,
        //         required: true
        //     }
        // },

        data() {
            return {
                show: false,
                formShow: false,
                state: true,

                radio: '1',

                healthInfo: {
                    name: "",
                    type: "", //1总部门 2下属部门
                    has_child: "", //是否有子级：1有 2无
                    pid: "",
                    // auth: "",
                    sort: "",
                },


                higher: {},


                isEdit: false

            }
        },

        mounted() {

        },

        created() {

        },

        watch: {

            show(value) {
                if (this.show) {

                    // this.$refs.AlonePower.refresh()

                } else {

                    this.$refs.AlonePower.refresh()

                    this.isEdit = false

                    this.higher = {}

                    this.healthInfo = {
                        name: "",
                        type: "", //1总部门 2下属部门
                        has_child: "", //是否有子级：1有 2无
                        pid: "",
                        // auth: "",
                        sort: "",
                    }

                }
            },

        },

        methods: {

            open() {
                this.formShow = true
                this.show = true
            },

            openChild(row) {

                this.healthInfo = {}

                this.higher = row
                this.healthInfo.pid = row.id

                this.open()
            },







            edit(row) {
                // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", row);

                this.isEdit = true

                this.healthInfo = {
                    ...row
                }

                this.open()


                // var a = this.healthInfo.auth.split(',').map(x => {
                //     return Number(x)
                // })


                // if (a.length == 1 && a[0] == 0) {
                //     console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", a);

                // } else {

                //     setTimeout(() => {
                //         this.$refs.AlonePower.someEdit(a)
                //     }, 500)

                // }








                // this.$http.get('/api/health/' + row.pid).then((res) => {

                //     if (res.code == 200) {
                //         this.higher = res.data
                //     }



                //     setTimeout(() => {
                //         this.healthInfo.city_id = row.city_id

                //         setTimeout(() => {
                //             this.healthInfo.region_id = row.region_id
                //         }, 1000)

                //     }, 1000) 


                // }).catch((err) => {
                //     console.log(err)
                // });

            },

            refresh() {

                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            save() {


                // var a = String(this.$refs.AlonePower.save())

                // this.healthInfo.auth = a

                // console.log(this.healthInfo);


                if (this.isEdit) {


                    this.$http.put('/api/health/' + this.healthInfo.id, this.healthInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);

                        }
                        if (res.code == 200) {

                            this.refresh()

                        }
                    }).catch((err) => {
                        console.log(err)
                    });

                } else {




                    this.$http.post('/api/health', this.healthInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);
                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                        if (res.code == 200) {

                            this.refresh()

                        }
                    }).catch((err) => {
                        console.log(err)
                    });



                }

            }

        }
    }
</script>

<style>
    /* 	.selfInputBoxIV .el-input__inner[placeholder="请选择所属卫健委"] {
		border: 1px red solid !important;
		box-sizing: content-box !important;
	} */

    .selfInputBoxIV .el-input__suffix {
        /*    border: 1px red solid !important;
    box-sizing: content-box !important; */
        right: -10px;
    }
</style>

<style scoped lang="scss">
    .trisection {
        .el-select {
            /deep/ .el-input__inner {
                // border: 1px red solid !important;
                box-sizing: content-box !important;
            }
        }
    }
</style>